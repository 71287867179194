.login-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.logologinchild {
    transform: rotate(270deg);
}

.logologinparent {
    overflow: hidden
}